import {useUserAttributes} from "../../../../hooks/useUserAttributes.ts";
import {
    DashboardWidget,
    WidgetBody,
    WidgetDataPoint,
    WidgetFooter,
    WidgetHeader,
    WidgetType
} from "@flexinet/ui-components";
import {NavLink} from "react-router-dom";

export const ProfileWidget = () => {
    const {
        givenName,
        familyName,
        email,
        companyName
    } = useUserAttributes()


    const widget: WidgetType = {
        header: {
            title: `Good Morning, ${givenName} ${familyName}`,
        },
        body: {
            dataPoints: [
                {
                    value: null,
                    label: `Organization: ${companyName}`,
                    color: 'black',
                },
                {
                    value: null,
                    label: `Email: ${email}`,
                    color: 'black',
                },
            ] as WidgetDataPoint[],
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'Update Profile',
                    description: 'Update your platform profile',
                    component: {NavLink},
                    to: '/',
                    visible: true,
                },
            ],
        }
    };

    // return <DashboardWidgetBuilder widget={widget}/>;
    return <DashboardWidget
        header={<WidgetHeader  {...widget.header}/>}
        body={<WidgetBody {...widget.body}/>}
        footer={<WidgetFooter {...widget.footer}/>}
    />


};
