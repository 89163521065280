import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import {PublicHomePage} from './features/home/pages/PublicHomePage';
import {SignOutPage} from './features/home/pages/SignOutPage';

import {IndexPage as DashboardPage} from "./features/dashboard/pages/IndexPage";
import {AppLayout} from "./layout/AppLayout";

import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {RequireAuth} from "./utils/RequireAuth.tsx";
import {UserAttributesContextProvider} from "./context/UserAttributesContext.tsx";
import {theme} from "./theme.ts";
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {ModalsProvider} from "@mantine/modals";

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

import {ApplicationsLayout} from "./features/applications/layout.tsx";
import {AccountsLayout} from "./features/accounts/layout.tsx";
import {UsersLayout} from "./features/users/layout.tsx";
import {OrganizationsLayout} from "./features/organizations/layout.tsx";

const router = createBrowserRouter([{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>
    }, {
        path: 'sign-out',
        element: <SignOutPage/>
    }, {
        path: 'dashboard',
        element: <RequireAuth><DashboardPage/></RequireAuth>
    }, {
        path: 'applications/*',
        element: <RequireAuth><ApplicationsLayout/></RequireAuth>
    }, {
        path: 'organizations/*',
        element: <RequireAuth><OrganizationsLayout/></RequireAuth>
    }, {
        path: 'accounts/*',
        element: <RequireAuth><AccountsLayout/></RequireAuth>
    }, {
        path: 'users/*',
        element: <RequireAuth><UsersLayout/></RequireAuth>
    }]
}])


export const App = () => {
    return (
        <MantineProvider theme={theme}>
            <Authenticator.Provider>
                <UserAttributesContextProvider>
                    <ModalsProvider>
                        <Notifications position={'bottom-left'}/>
                        <RouterProvider router={router}/>
                    </ModalsProvider>
                </UserAttributesContextProvider>
            </Authenticator.Provider>
        </MantineProvider>
    );
}

