import {
    Box,
    Grid,
    Stack,
} from '@mantine/core';
import {useDashboard} from "../hooks/useDashboard.tsx";
import {ReactElement} from "react";

export const PortalDashboard = () => {

    const {items} = useDashboard()

    console.debug(items)

    return (
        <Grid mx={'10%'}>
            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                <Stack>
                    {items.map((item: { position: string, component: ReactElement }, index) => {
                        if (item.position === 'left') {
                            return (
                                <Box key={index}>{item.component}</Box>
                            )
                        }
                    })}
                </Stack>
            </Grid.Col>

            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                <Stack>
                    {items.map((item: { position: string, component: ReactElement }, index) => {
                        if (item.position === 'right') {
                            return (
                                <Box key={index}>{item.component}</Box>
                            )
                        }
                    })}
                </Stack>
            </Grid.Col>
        </Grid>
    );
};
