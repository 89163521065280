import PropTypes from 'prop-types';

import {
    ActionIcon,
    Anchor,
    Group,
    Stack,
    Tabs,
    Text,
} from '@mantine/core';
import {
    User,
    UserDimension,
} from '../types';
import {
    AiOutlineLink,
    AiOutlinePhone
} from "react-icons/ai";
import {Link} from "react-router-dom";
import {
    PlatformPropertyCard,
    PlatformPropertyCardCopyButtonDecorator,
    PlatformPropertyCardMailtoButtonDecorator
} from '@flexinet/ui-components';

const DimensionsTable = ({dimensions}: { dimensions: UserDimension[] }) => {
    return (
        <>
            {dimensions.map((dimension, index) => (<Stack key={index} gap={'xs'}>
                <Group ml={'1em'} gap={'xs'}><Text
                    fw={600}>Name:</Text><Text>{dimension.name}</Text></Group>
                <Group ml={'1em'} gap={'xs'}><Text
                    fw={600}>Value:</Text><Text>{dimension.value}</Text></Group>
            </Stack>))}
        </>
    );
};

DimensionsTable.propTypes = {
    dimensions: PropTypes.array,
};

export const Profile = ({record}: { record: User }) => {

    const properties = {
        personal: [
            {
                label: 'Name',
                value: record.name as string,
                span: 12,
            },
            {
                label: 'Given Name',
                value: record.givenName as string,
                span: 6,
            }, {
                label: 'Family Name',
                value: record.familyName as string,
                span: 6,
            }, {
                label: 'Email',
                value: record.email,
                decorator: (value: string) => <PlatformPropertyCardMailtoButtonDecorator value={value}/>,
                span: 6,
            },
            {
                label: 'Email is verified',
                value: record.emailVerified === "true" ? 'Yes' : 'No',
                span: 6,
            }, {
                label: 'Username',
                value: record.username as string,
                decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
                span: 6,
            },
            {
                label: 'Middle Name',
                value: record.middleName as string,
                span: 6,
            }],
        'contact': [
            {
                label: 'Address',
                value: record.address as string,
                span: 12,
            }, {
                label: 'Website',
                value: (<Group>{record.website} <Anchor href={record.website}
                                                        target={'_blank'}><ActionIcon><AiOutlineLink/></ActionIcon></Anchor></Group>),
                span: 12,
            },
            {
                label: 'Phone Number',
                value: record.phoneNumber ? (<Group>{record.phoneNumber} <Anchor
                    href={['dial', record.phoneNumber].join(':')}
                    target={'_blank'}><ActionIcon><AiOutlinePhone/></ActionIcon></Anchor></Group>) : null,
                span: 12,
            },


        ],
        'location': [
            {
                label: 'Locale',
                value: record.locale as string,
                span: 6,
            },
            {
                label: 'Zone Info',
                value: record.zoneInfo as string,
                span: 6,
            }
        ],
        'photo': [
            {
                label: 'Picture',
                value: record.picture as string,
                span: 12,
            },
        ],
        social: [
            {
                label: 'Profile',
                value: (<Group>{record.profile} <Anchor href={record.profile}
                                                        target={'_blank'}><ActionIcon><AiOutlineLink/></ActionIcon></Anchor></Group>),
                span: 12,
            },
            {
                label: 'Nickname',
                value: record.nickname as string,
                span: 12,
            }, {
                label: 'Birth Date',
                value: record.birthDate as string,
                formatAs: 'Date',
                span: 6,
            }, {
                label: 'Gender',
                value: record.gender as string,
                span: 6,
            },
            {
                label: 'Created',
                value: record.created as string,
                formatAs: 'Timestamp',
                span: 6,
            },
            {
                label: 'Last Updated',
                value: record.updated as string,
                formatAs: 'Timestamp',
                span: 6,
            },

        ],
        business: [
            {
                label: 'Account',
                value: (<Link to={`/accounts/${record.accountId}`}>{record.accountId}</Link>),
                span: 12,
            }, {
                label: 'Account Admin',
                value: record.accountAdmin as string,
                span: 12,
            }
        ]
    };


    return (
        <Tabs defaultValue={'personal'}>
            <Tabs.List>
                <Tabs.Tab value={'personal'}>Personal</Tabs.Tab>
                <Tabs.Tab value={'contact'}>Contact</Tabs.Tab>
                <Tabs.Tab value={'location'}>Location</Tabs.Tab>
                <Tabs.Tab value={'photo'}>Photo</Tabs.Tab>
                <Tabs.Tab value='social'>Social</Tabs.Tab>
                <Tabs.Tab value='business'>Business</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value={'personal'}><PlatformPropertyCard properties={properties.personal}/></Tabs.Panel>
            <Tabs.Panel value={'contact'}><PlatformPropertyCard properties={properties.contact}/></Tabs.Panel>
            <Tabs.Panel value={'location'}><PlatformPropertyCard properties={properties.location}/></Tabs.Panel>
            <Tabs.Panel value={'photo'}><PlatformPropertyCard properties={properties.photo}/></Tabs.Panel>
            <Tabs.Panel value={'social'}><PlatformPropertyCard properties={properties.social}/></Tabs.Panel>
            <Tabs.Panel value={'business'}><PlatformPropertyCard properties={properties.business}/></Tabs.Panel>

        </Tabs>

    );


};

Profile.propTypes = {
    record: PropTypes.shape({
                                name: PropTypes.string,
                                description: PropTypes.string,
                                status: PropTypes.string,
                                createdBy: PropTypes.shape({
                                                               givenName: PropTypes.string,
                                                               familyName: PropTypes.string,
                                                           }),
                                managedBy: PropTypes.shape({
                                                               givenName: PropTypes.string,
                                                               familyName: PropTypes.string,
                                                           }),
                                created: PropTypes.string,
                                updated: PropTypes.string,
                            }),
};


