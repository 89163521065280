import {
    deleteUser,
    fetchUser,
    saveUser,
    updateUser,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {User} from '../types';

export const useUser = (userId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, userId ? userId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof userId === 'undefined') {
            throw new Error('userId is required parameter');
        }
        dispatch(fetchUser(userId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: User) => {
        dispatch(saveUser(values));
    };

    const updateRecord = (values: User) => {
        if (typeof userId === 'undefined') {
            throw new Error('userId is required parameter');
        }

        dispatch(updateUser({
                                id: userId,
                                record: values,
                            }));
    };

    const deleteRecord = () => {
        if (typeof userId === 'undefined') {
            throw new Error('userId is required parameter');
        }
        dispatch(deleteUser(userId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
