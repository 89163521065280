export const createOrganization = `
    mutation createOrganization($input: CreateOrganizationInput!) {
        createOrganization(input:$input) {
            id
            name
            description
            email
            url
            phone
    }
}`;

export const updateOrganization = `
    mutation updateOrganization($input: UpdateOrganizationInput!) {
        updateOrganization(input:$input){
            id
            name
            description
            email
            url
            phone
        }
}`;

export const deleteOrganization = `
    mutation deleteOrganization($input: DeleteOrganizationInput!) {
        deleteOrganization(input:$input) 
}`;
