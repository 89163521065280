export const basicUserPermissions = [
    {
        Effect: 'Allow',
        Action: [
            'ViewAccount',
            'UpdateAccount',
        ],
        Resource: '*',
    },
    {
        Effect: 'Allow',
        Action: [
            'ViewDashboard',
            'ListUserSettings',
            'ListUserAgreements',
            'SignOut',
        ],
        Resource: '*',
    },
];

export default {
    roleName: 'User',
    permissionStatements: basicUserPermissions
};
