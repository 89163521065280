import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/UsersService';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {User} from '../types';
import moment from "moment";
import {recordSelector} from "../selectors.ts";
import {recordSelector as accountSelector} from "../../accounts";

const entityKey = 'users';

interface UsersState {
    byId: { [key: string]: User },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as UsersState;

const normalizeRecord = (record: User) => {
    return Object.assign({}, {
        ...record,
        id: record.sub,
    }) as User;
};

const sortUsersByCreatedDate = (users: User[]) => {
    return users.sort((a, b) => {
                          return moment(a.created)
                              .isBefore(moment(b.created)) ? 1 : -1
                      }
    )
}

// generates pending, fulfilled and rejected
export const fetchUsers = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (filter: { [key: string]: string }, {getState}) => {

        let newFilter = {}
        if (filter.accountId) {
            const state = getState()
            const {record: account} = accountSelector(state, filter.accountId)
            newFilter = {email: account.email}
        }

        return fetchRecords(newFilter)
            .then((response) => {
                if (response) {
                    const sorted = sortUsersByCreatedDate(response)
                    return sorted.map((record: User) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchUser = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string, {getState}) => {

        const state = getState()
        const {record: user} = recordSelector(state, id)

        return fetch(user.username)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const saveUser = createAsyncThunk(
    `${entityKey}/add`,
    (payload: User) => {

        // delete id from the object
        const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            id: myId,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            sub,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            emailVerified,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            phoneNumberVerified,
            ...rest
        } = payload;

        return add(rest)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateUser = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: User }, {getState}) => {

        const state = getState()
        const {record: user} = recordSelector(state, id)

        const birthDate = moment(record.birthDate)
            .format('YYYY-MM-DD')

        console.debug('IN UPDATE', birthDate);

        // delete id from the object
        const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            id: myId,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            sub,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            emailVerified,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            phoneNumberVerified,
            ...rest
        } = record;


        return update(user.username, rest as User)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response as User);
                }
            });
    },
);

export const deleteUser = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string, {getState}) => {

        const state = getState()
        const {record: user} = recordSelector(state, id)

        return deleteRecord(user.username)
            .then(() => id);
    },
);


export const storeUser = createAsyncThunk(
    `${entityKey}/store`,
    (payload: User) => {
        return normalizeRecord(payload as User);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchUsers, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchUser);

                                            // SAVE ONE
                                            saveOneCase(builder, saveUser);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateUser);

                                            updateOneCase(builder, storeUser);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteUser);
                                        },
                                    });

export default componentsSlice.reducer;
