export const createApplication = `
    mutation createApplication($input: CreateApplicationInput!) {
        createApplication(input:$input) {
            id
            name
    }
}`;

export const updateApplication = `
    mutation updateApplication($input: UpdateApplicationInput!) {
        updateApplication(input:$input){
            id
            name
        }
}`;

export const deleteApplication = `
    mutation deleteApplication($input: DeleteApplicationInput!) {
        deleteApplication(input:$input) 
}`;
