import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {EditPage} from './pages/EditPage';

import {useAccessControl} from '../../hooks/useAccessControl';
import {useUserAttributes} from "../../hooks/useUserAttributes.ts";

export const UsersLayout = () => {

    const {role} = useUserAttributes();

    const {evaluateAction} = useAccessControl(role)


    return (
        <Routes>
            <Route
                path='/'
                element={
                    evaluateAction('ListUsers') ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to='/'/>
                    )
                }
            />
            <Route
                path='add'
                element={
                    evaluateAction('CreateUser') ? (
                        <AddPage/>
                    ) : (
                        <Navigate to='/users'/>
                    )
                }
            />
            <Route
                path=':id'
                element={
                    evaluateAction('GetUser') ? (
                        <EditPage/>
                    ) : (
                        <Navigate to='/users'/>
                    )
                }
            />
        </Routes>
    );
};
