import {
    deleteApplication,
    fetchApplication,
    saveApplication,
    updateApplication,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Application} from '../types';

export const useApplication = (applicationId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, applicationId ? applicationId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof applicationId === 'undefined') {
            throw new Error('applicationId is required parameter');
        }
        dispatch(fetchApplication(applicationId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Application) => {
        dispatch(saveApplication(values));
    };

    const updateRecord = (values: Application) => {
        if (typeof applicationId === 'undefined') {
            throw new Error('applicationId is required parameter');
        }

        dispatch(updateApplication({
                                       id: applicationId,
                                       record: values,
                                   }));
    };

    const deleteRecord = () => {
        if (typeof applicationId === 'undefined') {
            throw new Error('applicationId is required parameter');
        }
        dispatch(deleteApplication(applicationId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
