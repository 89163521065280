import {
    getUser,
    listUsers
} from './queries';
import {
    createUser,
    deleteUser,
    updateUser,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {
    CreateUser,
    DeleteUser,
    GetUser,
    ListUsers,
    UpdateUser,
    User
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";

const normalizeFilter = (filter: { [key: string]: string }) => {
    return Object.keys(filter)
                 .reduce((acc: { [key: string]: object }, key: string) => {
                     if (filter[key] !== '') {
                         acc[key] = {eq: filter[key]};
                     }
                     return acc;
                 }, {});

};

export const fetchRecords = async (filter?: { [key: string]: string }) => {

    let transformedFilter = {};
    if (typeof filter !== 'undefined') {
        transformedFilter = normalizeFilter(filter);
    }

    try {
        const response = await API.graphql<GraphQLQuery<ListUsers>>({
                                                                        query: listUsers,
                                                                        variables: {filter: transformedFilter},
                                                                    });
        return response.data?.listUsers.items;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const fetch = async (username: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetUser>>({
                                                                      query: getUser,
                                                                      variables: {username: username},
                                                                  });
        return response.data?.getUser;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreateUser>>({
                                                                         query: createUser,
                                                                         variables: {input: payload},
                                                                     });
        notifySuccess();
        return response.data?.createUser;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (username: string, record: User) => {

    console.debug('IN UPDATE', username, record);

    delete record.created;
    delete record.updated;


    try {
        const response = await API.graphql<GraphQLQuery<UpdateUser>>({
                                                                         query: (updateUser),
                                                                         variables: {input: record},
                                                                     });

        console.debug('IN UPDATE RESPONSE', response.data);
        notifySuccess('User updated successfully');
        return response.data?.updateUser;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (username: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeleteUser>>({
                                                                         query: (deleteUser),
                                                                         variables: {input: {username: username}},

                                                                     });
        notifySuccess();
        return response.data?.deleteUser;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
