import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Profile} from '../components/Profile';

import {EditPageNavigation} from '../components/EditPageNavigation';
import {useApplication} from '../hooks/useApplication.ts';
import {useApplicationsSelection} from '../hooks/useApplicationsSelection.ts';
import {useEditPageTools} from '../hooks/useEditPageTools';

import {IndexPage as AccountsPage} from '../../accounts/pages/IndexPage.tsx'
import {useApplications} from "../hooks/useApplications.ts";


export const EditPage = () => {

    const {id: applicationId} = useParams();

    if (typeof applicationId === 'undefined') {
        throw new Error('applicationId is required parameter');
    }

    const {fetchRecords} = useApplications();

    const {
        record: application,
        fetchRecord,
        updateRecord,
    } = useApplication(applicationId);

    const {handleClearSelection} = useApplicationsSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [applicationId]);

    if (!application) {
        return <PlatformLoader message='Loading application...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={application.name ? application.name : application.id}
            pageNavigation={<EditPageNavigation component={application}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={application}
                    />

                    <AccountsPage/>

                </Stack>
                :
                <RecordForm
                    record={application}
                    handleSubmit={updateRecord}
                />}


        </FormPageLayout>
    );
};
