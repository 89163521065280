import {useEffect,} from 'react';

import {allComponentsSelector} from '../selectors';

import {useOrganizations} from '../hooks/useOrganizations.ts';
import {Organization} from '../types';
import {FieldProps} from 'formik';
import {useAppSelector} from "../../../hooks/useTypedReactRedux.ts";
import {FormikClearableCombobox} from '@flexinet/ui-components';

export const OrganizationsAutocomplete = (props: FieldProps) => {

    const {fetchRecords} = useOrganizations();

    useEffect(() => {
        fetchRecords();
    }, []);


    const records = useAppSelector((state) => allComponentsSelector(state));
    const data = records.map((record: Organization) => {
        return {
            ...record,
            label: record.name,
            value: record.id,
        };
    });

    return (
        <FormikClearableCombobox
            data={data}
            {...props}
        />
    );
};