import {
    configureStore,
    Store
} from '@reduxjs/toolkit';
import {ApplicationsReducer} from "../features/applications/reducers";
import {AccountsReducer} from "../features/accounts/reducers";
import {UsersReducer} from "../features/users/reducers";
import {OrganizationsReducer} from "../features/organizations/reducers";

const reducers = {
    accounts: AccountsReducer,
    applications: ApplicationsReducer,
    organizations: OrganizationsReducer,
    users: UsersReducer
};

// added this middleware  to disable this error - A non-serializable value was detected in the state, in the path...
// @todo disable if better way is found
export const store: Store = configureStore({
                                               reducer: reducers,
                                               middleware: (getDefaultMiddleware) =>
                                                   getDefaultMiddleware({
                                                                            serializableCheck: false,
                                                                        }),
                                           });


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch