import {I18n} from '@aws-amplify/core';

import {
    DeleteRecordsConfirmationButton,
    FlexiAddButton,
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useNavigate} from 'react-router-dom';

import {useUsers} from '../hooks/useUsers';

import {useAccessControl} from '../../../hooks/useAccessControl';
import PropTypes from 'prop-types';
import {useUsersSelection} from "../hooks/useUsersSelection.ts";
import {useUserAttributes} from "../../../hooks/useUserAttributes.ts";

const ComponentsReloadButton = () => {

    const {fetchRecords} = useUsers();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};

const AddRecordButton = ({
                             buttonText = 'Add User',
                             size = 'sm',
                             onClick,
                         }: {
    buttonText?: string,
    size?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('add');
        };
    }

    return (
        <FlexiAddButton
            onClick={onClick}
            size={size}
        >
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};

const DeleteRecordButton = () => {

    const {deleteRecords} = useUsers();

    const {
        selection,
        handleClearSelection,
    } = useUsersSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }

    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);

};


const useTools = () => {

    const {role} = useUserAttributes();

    const {evaluateAction} = useAccessControl(role)

    const tools = [<ComponentsReloadButton key='reload'/>];

    if (evaluateAction('DeleteUser')) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (evaluateAction('CreateUser')) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Users')}
            tools={useTools()}
        />
    );
};

AddRecordButton.propTypes = {
    buttonText: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
};