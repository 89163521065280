export const listOrganizations = `
   query listOrganizations {
       listOrganizations {
           items {
              id
              name
              description
              email
              url
              phone
              created
              updated
           }
       }
   }
`;

export const getOrganization = `
   query getOrganization($id:ID!) {
       getOrganization(id:$id) {
         id
         name
         description
         email
         url
         phone
         created
         updated
       } 
}`;
