import {Field} from 'formik';

import {Grid} from '@mantine/core';

import {
    FormikSwitch,
    FormikTextInput
} from '@flexinet/ui-components';

export const PersonalPanel = () => {

    return (
        <Grid p={'sm'} my={'sm'}>

            <Grid.Col span={12}>
                <Field
                    name='name'
                    label='Name'
                    description='Please enter name'
                    component={FormikTextInput}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={6}>
                <Field
                    name='givenName'
                    label='Given Name'
                    description='Please enter given name'
                    component={FormikTextInput}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={6}>
                <Field
                    name='familyName'
                    label='Family Name'
                    description='Please enter family name'
                    component={FormikTextInput}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={6}>
                <Field
                    name='email'
                    label='Email'
                    description='Please enter email'
                    component={FormikTextInput}
                />
            </Grid.Col>

            <Grid.Col span={6}>
                <Field
                    name='emailVerified'
                    label='Email Verified'
                    description='Please select email verified'
                    component={FormikSwitch}
                />
            </Grid.Col>

            <Grid.Col span={6}>
                <Field
                    name='middleName'
                    label='Middle Name'
                    description='Please enter middleName'
                    component={FormikTextInput}
                />
            </Grid.Col>


        </Grid>)

};