import {
    getApplication,
    listApplications
} from './queries';
import {
    createApplication,
    deleteApplication,
    updateApplication,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {Application} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";


export type ListApplications = {
    listApplications: { items: Application[] }
}

export type GetApplication = {
    getApplication: Application
}

export type CreateApplication = {
    createApplication: Application
}

export type UpdateApplication = {
    updateApplication: Application
}

export type DeleteApplication = {
    deleteApplication: string
}

export const fetchRecords = async (filter: object) => {
    try {
        const response = await API.graphql<GraphQLQuery<ListApplications>>({
                                                                               query: listApplications,
                                                                               variables: {filter: filter},
                                                                           });
        return response.data?.listApplications.items;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetApplication>>({
                                                                             query: getApplication,
                                                                             variables: {id: id},
                                                                         });
        return response.data?.getApplication;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreateApplication>>({
                                                                                query: createApplication,
                                                                                variables: {input: payload},
                                                                            });
        console.debug('in the service response', response);

        notifySuccess();
        return response.data?.createApplication;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: Application) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await API.graphql<GraphQLQuery<UpdateApplication>>({
                                                                                query: (updateApplication),
                                                                                variables: {input: record},
                                                                            });

        notifySuccess();
        return response.data?.updateApplication;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeleteApplication>>({
                                                                                query: (deleteApplication),
                                                                                variables: {input: {id: id}},

                                                                            });
        notifySuccess();
        return response.data?.deleteApplication;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
