import {Field} from 'formik';

import {Grid} from '@mantine/core';

import {FormikSelect} from '@flexinet/ui-components';
import {AccountsAutocomplete} from "../../../accounts/inputs/AccountsAutocomplete.tsx";

export const BusinessPanel = () => {

    return (
        <Grid p={'sm'} my={'sm'}>

            <Grid.Col span={12}>
                <Field
                    name='accountId'
                    label='Account'
                    description='Please select account'
                    component={AccountsAutocomplete}
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='accountAdmin'
                    label='Account Admin'
                    description='Please select if is account admin'
                    data={[{
                        label: 'No',
                        value: 'no'
                    }, {
                        label: 'Yes',
                        value: 'yes'
                    }]}
                    component={FormikSelect}
                />
            </Grid.Col>

        </Grid>

    );
};