import {useNavigate} from 'react-router-dom';

import {useAccessControl} from '../../../hooks/useAccessControl';
import {useToggle} from '@mantine/hooks';


import {
    AiOutlineEdit,
    AiOutlineProfile
} from 'react-icons/ai';
import {IconContext} from 'react-icons';
import {useUserAttributes} from "../../../hooks/useUserAttributes.ts";
import {
    FlexiButton,
    FlexiCancelButton
} from "@flexinet/ui-components";

export const useEditPageTools = () => {

    const navigate = useNavigate();

    const {role} = useUserAttributes();

    const {evaluateAction} = useAccessControl(role)

    const [mode, toggle] = useToggle(['view', 'edit']);

    const tools = [];


    if (evaluateAction('UpdateOrganization')) {

        if (mode === 'edit') {
            tools.push(<FlexiButton variant={'filled'} key={'profile'}
                                    leftSection={<IconContext.Provider value={{size: '24px'}}>
                                        <AiOutlineProfile/></IconContext.Provider>}
                                    onClick={() => toggle()}>Profile</FlexiButton>);
        } else {
            tools.push(<FlexiButton leftSection={<AiOutlineEdit/>} variant={'filled'} key={'edit'}
                                    onClick={() => toggle()}>Edit</FlexiButton>);
        }

    }

    tools.push(<FlexiCancelButton key={'close'}
                                  onClick={() => navigate(`/organizations`)}/>);


    return {
        mode,
        tools,
    };

};
