import PropTypes from 'prop-types';

import {Tabs} from '@mantine/core';
import {PersonalPanel} from "./panels/PersonalPanel.tsx";
import {ContactPanel} from "./panels/ContactPanel.tsx";
import {LocationPanel} from "./panels/LocationPanel.tsx";
import {PhotoPanel} from "./panels/PhotoPanel.tsx";
import {BusinessPanel} from "./panels/BusinessPanel.tsx";
import {SocialPanel} from "./panels/SocialPanel.tsx";


export const FormComponent = () => {

    return (
        <Tabs defaultValue={'personal'}>
            <Tabs.List>
                <Tabs.Tab value='personal'>Personal</Tabs.Tab>
                <Tabs.Tab value='contact'>Contact</Tabs.Tab>
                <Tabs.Tab value='location'>Location</Tabs.Tab>
                <Tabs.Tab value='photo'>Photo</Tabs.Tab>
                <Tabs.Tab value='social'>Social</Tabs.Tab>
                <Tabs.Tab value='business'>Business</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='personal'>
                <PersonalPanel/>
            </Tabs.Panel>
            <Tabs.Panel value='contact'>
                <ContactPanel/>
            </Tabs.Panel>
            <Tabs.Panel value='location'>
                <LocationPanel/>
            </Tabs.Panel>
            <Tabs.Panel value='photo'>
                <PhotoPanel/>
            </Tabs.Panel>
            <Tabs.Panel value='social'>
                <SocialPanel/>
            </Tabs.Panel>
            <Tabs.Panel value='business'>
                <BusinessPanel/>
            </Tabs.Panel>
        </Tabs>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
