import {AiOutlineArrowUp} from 'react-icons/ai';
import {
    DashboardWidgetBuilder,
    WidgetType
} from "@flexinet/ui-components";
import {NavLink} from "react-router-dom";

export const SupportWidget = () => {
    const widget: WidgetType = {
        header: {
            title: 'Support Cases',
        },
        body: {
            dataPoints: [
                {
                    value: 1,
                    label: 'Open',
                    color: 'blue'
                },
                {
                    value: 3,
                    label: 'Resoloved',
                    color: 'green'
                },
            ],
            metrics: [
                {
                    value: 15,
                    unit: 'Hours',
                    label: 'Average Resolution Time',
                    color: 'black',
                    insight: {
                        icon: <AiOutlineArrowUp color="blue"/>,
                        label: 'improving month over month',
                        color: 'black',
                    },
                    visible: true
                },
            ],
        },
        footer: {
            actionItems: [
                {
                    label: 'Open Support Case',
                    description:
                        'Use Atlassian to submit question or send help request',
                    visible: true,
                    component: {NavLink},
                    to: '/support',
                },
            ],
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
