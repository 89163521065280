import {Field} from 'formik';

import {Grid} from '@mantine/core';

import {
    FormikSwitch,
    FormikTextInput
} from '@flexinet/ui-components';

export const ContactPanel = () => {

    return (
        <Grid p={'sm'} my={'sm'}>

            <Grid.Col span={12}>
                <Field
                    name='address'
                    label='Address'
                    description='Please enter address'
                    component={FormikTextInput}
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='website'
                    label='Website'
                    description='Please enter website'
                    component={FormikTextInput}
                />
            </Grid.Col>


            <Grid.Col span={6}>
                <Field
                    name='phoneNumber'
                    label='Phone Number'
                    description='Please enter phone number +17141234567'
                    component={FormikTextInput}
                />
            </Grid.Col>

            <Grid.Col span={6}>
                <Field
                    mt={'xl'}
                    name='phoneNumberVerified'
                    label='Phone Number is verified'
                    description='Please check if number is verified'
                    component={FormikSwitch}
                />
            </Grid.Col>


        </Grid>

    )
}
