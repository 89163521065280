import {
    useEffect,
    useState,
} from 'react';

import {Autocomplete,} from '@mantine/core';

import {useAccounts} from '../hooks/useAccounts';
import {Account} from '../types';
import {FieldProps} from 'formik';

export const AccountsAutocomplete = ({
                                         field: {
                                             name,
                                             value: fieldValue,
                                         },
                                         form: {setFieldValue},
                                         ...rest
                                     }: FieldProps) => {


    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(false);

    const {
        items: accounts,
        fetchRecords
    } = useAccounts();

    useEffect(() => {
        fetchRecords();
    }, []);

    const data = accounts.map((record: Account) => {
        return {
            label: record.name,
            value: record.id,
        };
    });


    useEffect(() => {
        if (accounts.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

        const found = accounts.find((item: Account) => item.id === fieldValue);
        if (typeof found !== 'undefined' && Object.prototype.hasOwnProperty.call(found, 'name')) {
            setValue(found.name);
        }
    }, []);


    const handleChange = (value: string) => {

        if (!Array.isArray(data)) {
            throw new Error('Data is expected to be an array');
        }
        const {id} = accounts.find((item: Account) => item.name === value) || {};


        if (typeof id !== 'undefined') {
            setFieldValue(name, id);
        }

        setValue(value);
    };

    return (
        <Autocomplete
            {...rest}
            name={name}
            value={value}
            onChange={handleChange}
            data={data}
            disabled={disabled}
            placeholder='Select Component'
        />
    );
};
