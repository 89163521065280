import {useAccessControl} from "../../../hooks/useAccessControl.ts";
import {useUserAttributes} from "../../../hooks/useUserAttributes.ts";
import {UsersWidget} from "../components/dashboard/UsersWidget.tsx";
import {ApplicationsWidget} from "../components/dashboard/ApplicationsWidget.tsx";
import {OrganizationsWidget} from "../components/dashboard/OrganizationsWidget.tsx";
import {SupportWidget} from "../components/dashboard/SupportWidget.tsx";
import {ProfileWidget} from "../components/dashboard/ProfileWidget.tsx";
import {HealthWidget} from "../components/dashboard/HealthWidget.tsx";

export const useDashboard = () => {

    const {
        role,
    } = useUserAttributes();

    const {evaluateAction} = useAccessControl(role)

    const items = [{
        name: 'profile',
        component: <ProfileWidget/>,
        position: 'left',
        rbac: 'ViewAccount'
    }, {
        name: 'users',
        component: <UsersWidget/>,
        position: 'left',
        rbac: 'ListUsers'
    }, {
        name: 'applications',
        component: <ApplicationsWidget/>,
        position: 'right',
        rbac: 'ListApplications'
    }, {
        name: 'organizations',
        component: <OrganizationsWidget/>,
        position: 'right',
        rbac: 'ListOrganizations'
    }, {
        name: 'support',
        component: <SupportWidget/>,
        position: 'left',
        rbac: 'GetSupport'
    }, {
        name: 'health',
        component: <HealthWidget/>,
        position: 'right',
        rbac: 'GetPlatformHealth'
    },]

    const filtered = items.filter((item: { rbac: string }) => evaluateAction(item.rbac))

    return {items: filtered}

}