import {PlatformNavbar} from "@flexinet/ui-components";
import {getMenuItems} from "../data/menus.tsx";
import {useAccessControl} from "../../../hooks/useAccessControl.ts";
import {useUserAttributes} from "../../../hooks/useUserAttributes.ts";

export const PortalNavbar = () => {


    const {role} = useUserAttributes();

    const {evaluateAction} = useAccessControl(role)

    const items = getMenuItems()

    const menuItems = items.filter((item) => {
        return evaluateAction(item.rbac as string)
    });


    return <PlatformNavbar
        navbarHeader={"Admin Console"}
        menuItems={menuItems}/>

};