export const listApplications = `
   query listApplications {
       listApplications {
           items {
              id
              name
           }
       }
   }
`;

export const getApplication = `
   query getApplication($id:ID!) {
       getApplication(id:$id) {
         id
         name
       } 
}`;
