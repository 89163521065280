import {NavLink as ReactRouteNavLink} from 'react-router-dom';
import {useEffect} from "react";
import {
    DashboardWidgetBuilder,
    WidgetDataPoint,
    WidgetType
} from "@flexinet/ui-components";
import {useOrganizations} from "../../../organizations/hooks/useOrganizations.ts";

export const OrganizationsWidget = () => {

    const {
        items,
        fetchRecords
    } = useOrganizations()

    useEffect(() => {
        fetchRecords()
    }, []);

    const dataPoints: WidgetDataPoint[] = [];
    dataPoints.push({
                        value: items.length,
                        label: 'Organizations',
                        color: 'flexinet',
                    });


    const widget: WidgetType = {
        header: {
            title: 'Organizations',
        },
        body: {
            dataPoints: dataPoints,
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'View All',
                    description:
                        'View All Organizations',
                    component: {ReactRouteNavLink},
                    to: '/organizations',
                    visible: true
                },
                {
                    label: 'Onboard organizations',
                    description:
                        'Onboard new organization',
                    component: {ReactRouteNavLink},
                    to: '/organizations/add',
                    visible: true,
                },
            ],
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
