import {
    createContext,
    ReactNode,
    useEffect,
    useState,
} from 'react';

import PropTypes from 'prop-types';
import {useAuthenticator} from "@aws-amplify/ui-react";
import {Box} from "@mantine/core";
import {PlatformLoader} from "@flexinet/ui-components";

type UserAttributesContextType = {
    givenName: string,
    familyName: string,
    email: string,
    companyName: string,
    role: string,
    tier: string
}

export const UserAttributesContext = createContext<UserAttributesContextType>({
                                                                                  givenName: '',
                                                                                  familyName: '',
                                                                                  email: '',
                                                                                  companyName: '',
                                                                                  role: '',
                                                                                  tier: '',
                                                                              });


type UserAttributesContextProviderProps = {
    children?: ReactNode;
}

export const UserAttributesContextProvider = ({
                                                  children,
                                              }: UserAttributesContextProviderProps) => {


    const [givenName, setGivenName] = useState('')
    const [familyName, setFamilyName] = useState('')
    const [email, setEmail] = useState('')
    const [companyName, setCompanyName] = useState('')
    // const [role, setRole] = useState('')
    const [tier, setTier] = useState('')

    const {
        route,
        user
    } = useAuthenticator(context => [context.user])


    const cognitoGroups = user && user.getSignInUserSession()
                                      ?.getIdToken().payload['cognito:groups']


    let role = 'User'

    const orgAdminItem = cognitoGroups && cognitoGroups.find((item: string) => item === 'org_admin')
    if (orgAdminItem) {
        role = 'OrgAdmin'
    }


    const adminItem = cognitoGroups && cognitoGroups.find((item: string) => item === 'admin')
    if (adminItem) {
        role = 'Admin'
    }


    useEffect(() => {
        if (route == 'authenticated') {
            user.getUserAttributes((err, attributes) => {

                if (err) {
                    console.error('IN UserAttributesContextProvider', err)
                    throw new Error(err.message)
                }


                attributes?.forEach(attribute => {

                    switch (attribute.getName()) {
                        case 'given_name':
                            setGivenName(attribute.getValue())
                            break
                        case 'family_name':
                            setFamilyName(attribute.getValue())
                            break
                        case 'email':
                            setEmail(attribute.getValue())
                            break
                        case 'custom:companyName':
                            setCompanyName(attribute.getValue())
                            break
                        // case 'custom:role':
                        //     setRole(attribute.getValue())
                        //     break
                        case 'custom:tier':
                            setTier(attribute.getValue())
                            break
                    }

                })

            })

        }
    }, [route])


    if (!user) {
        return children
    }

    return (
        <UserAttributesContext.Provider
            value={{
                givenName,
                familyName,
                email,
                companyName,
                role,
                tier
            }}
        >
            <>
                {role === '' ?
                    <PlatformLoader message={'Loading user attributes...Please wait!'}/> :
                    <Box>{children}</Box>
                }
            </>
        </UserAttributesContext.Provider>
    );
};

UserAttributesContextProvider.propTypes = {
    children: PropTypes.node,
};

