import {RecordForm} from '../forms/RecordForm';


import {useApplication} from '../hooks/useApplication.ts';
import {IndexPageNavigation} from "../components/IndexPageNavigation";
import {FormPageLayout} from "@flexinet/ui-components";


export const AddPage = () => {

    const {
        record,
        createRecord,
    } = useApplication();

    return (
        <FormPageLayout
            title='Add Application'
            pageNavigation={<IndexPageNavigation/>}
        >
            <RecordForm record={record} handleSubmit={createRecord}/>
        </FormPageLayout>
    );
};
