import {Field} from 'formik';

import {Grid} from '@mantine/core';

import {
    FormikDatePickerInput,
    FormikRadioGroup,
    FormikTextInput
} from '@flexinet/ui-components';

export const SocialPanel = () => {

    return (
        <Grid p={'sm'} my={'sm'}>

            <Grid.Col span={12}>
                <Field
                    name='profile'
                    label='Profile'
                    description='Please enter profile url'
                    component={FormikTextInput}
                />
            </Grid.Col>


            <Grid.Col span={12}>
                <Field
                    name='nickname'
                    label='Nickname'
                    description='Please enter nickname'
                    component={FormikTextInput}
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='gender'
                    label='Gender'
                    description='Please select gender'
                    data={[{
                        label: 'Female',
                        value: 'female'
                    }, {
                        label: 'Male',
                        value: 'male'
                    }, {
                        label: 'Rather not say',
                        value: ''
                    }]}
                    component={FormikRadioGroup}
                />


            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='birthDate'
                    label='Birth Date'
                    description='Please enter birth date'
                    component={FormikDatePickerInput}
                />
            </Grid.Col>

        </Grid>)
}
