export const createAccount = `
    mutation createAccount($input: CreateAccountInput!) {
        createAccount(input:$input) {
            id
            name
            email
            status
            organizationId
    }
}`;

export const updateAccount = `
    mutation updateAccount($input: UpdateAccountInput!) {
        updateAccount(input:$input){
            id
            name
            email
            status
            organizationId
        }
}`;

export const deleteAccount = `
    mutation deleteAccount($input: DeleteAccountInput!) {
        deleteAccount(input:$input) 
}`;
