import {
    getOrganization,
    listOrganizations
} from './queries';
import {
    createOrganization,
    deleteOrganization,
    updateOrganization,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {Organization} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";


export type ListOrganizations = {
    listOrganizations: { items: Organization[] }
}

export type GetOrganization = {
    getOrganization: Organization
}

export type CreateOrganization = {
    createOrganization: Organization
}

export type UpdateOrganization = {
    updateOrganization: Organization
}

export type DeleteOrganization = {
    deleteOrganization: string
}

export const fetchRecords = async (filter: object) => {
    try {
        const response = await API.graphql<GraphQLQuery<ListOrganizations>>({
                                                                                query: listOrganizations,
                                                                                variables: {filter: filter},
                                                                            });
        return response.data?.listOrganizations.items;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetOrganization>>({
                                                                              query: getOrganization,
                                                                              variables: {id: id},
                                                                          });
        return response.data?.getOrganization;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreateOrganization>>({
                                                                                 query: createOrganization,
                                                                                 variables: {input: payload},
                                                                             });
        console.debug('in the service response', response);

        notifySuccess();
        return response.data?.createOrganization;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: Organization) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await API.graphql<GraphQLQuery<UpdateOrganization>>({
                                                                                 query: (updateOrganization),
                                                                                 variables: {input: record},
                                                                             });

        notifySuccess();
        return response.data?.updateOrganization;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeleteOrganization>>({
                                                                                 query: (deleteOrganization),
                                                                                 variables: {input: {id: id}},
                                                                             });
        notifySuccess();
        return response.data?.deleteOrganization;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
