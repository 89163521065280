import {Field} from 'formik';

import {Grid} from '@mantine/core';

import {FormikTextInput} from '@flexinet/ui-components';

export const PhotoPanel = () => {

    return (
        <Grid p={'sm'} my={'sm'}>

            <Grid.Col span={6}>
                <Field
                    name='picture'
                    label='Picture'
                    description='Please enter picture'
                    component={FormikTextInput}
                />
            </Grid.Col>


        </Grid>
    )
}