import {Field} from 'formik';

import {Grid} from '@mantine/core';

import {FormikAutocomplete} from '@flexinet/ui-components';

import locales from '../../../../data/locales.json';

declare namespace Intl {
    type Key = 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit';

    function supportedValuesOf(input: Key): string[];
}

const aryIanaTimeZones = Intl.supportedValuesOf('timeZone');


export const LocationPanel = () => {

    return (
        <Grid p={'sm'} my={'sm'}>

            <Grid.Col span={12}>
                <Field
                    name='locale'
                    label='Locale'
                    description='Please enter locale'
                    component={FormikAutocomplete}
                    data={locales.map(locale => {
                        return {
                            id: locale.key,
                            value: locale.key,
                            label: locale.name
                        }
                    })}
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='zoneInfo'
                    label='Zoneinfo'
                    description='Please enter zoneinfo'
                    component={FormikAutocomplete}
                    data={aryIanaTimeZones.map(zone => {
                        return {
                            id: zone,
                            value: zone,
                            label: zone
                        }
                    })}

                />
            </Grid.Col>


        </Grid>
    )
}