export const createUser = `
    mutation createUser($input: CreateUserInput!) {
        createUser(input:$input) {
              accountId
              accountAdmin
              address 
              birthDate
              email
              emailVerified
              enabled
              familyName
              gender
              givenName
              locale
              middleName
              name
              nickname
              phoneNumber
              phoneNumberVerified
              picture
              profile
              status
              username
              website
              zoneInfo
              sub
              created
              updated
    }
}`;

export const updateUser = `
    mutation updateUser($input: UpdateUserInput!) {
        updateUser(input:$input){
              accountId
              accountAdmin
              address 
              birthDate
              email
              emailVerified
              enabled
              familyName
              gender
              givenName
              locale
              middleName
              name
              nickname
              phoneNumber
              phoneNumberVerified
              picture
              profile
              status
              username
              website
              zoneInfo
              sub
              created
              updated
        }
}`;

export const deleteUser = `
    mutation deleteUser($input: DeleteUserInput!) {
        deleteUser(input:$input) 
}`;
