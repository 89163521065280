import {basicUserPermissions} from "./user.ts";

export default {
    roleName: 'AccountAdmin',
    permissionStatements: basicUserPermissions.concat([
                                                          {
                                                              Effect: 'Allow',
                                                              Action: [
                                                                  'ListSubscriptions',
                                                                  'GetSubscription',
                                                                  'CreateSubscription',
                                                                  'UpdateSubscription',
                                                              ],
                                                              Resource: '*',
                                                          },
                                                          {
                                                              Effect: 'Allow',
                                                              Action: [
                                                                  'ListPayments',
                                                                  'GetPayment',
                                                                  'CreatePayment',
                                                                  'UpdatePayment',
                                                              ],
                                                              Resource: '*',
                                                          },
                                                          {
                                                              Effect: 'Allow',
                                                              Action: [
                                                                  'ListUsers',
                                                                  'GetUser',
                                                                  'CreateUser',
                                                                  'UpdateUser',
                                                              ],
                                                              Resource: '*',
                                                          },
                                                          {
                                                              Effect: 'Allow',
                                                              Action: [
                                                                  'GetPlatformHealth',
                                                              ],
                                                              Resource: '*',
                                                          },
                                                      ]),
};
