import {stage} from '../stage';

const cognito = {
    prod: {
        domain: 'flexinet-main-user-pool-prod.auth.us-west-2.amazoncognito.com',
        redirectSignIn: 'https://console.flexi.network',
        redirectSignOut: 'https://portal.flexi.network',
        region: 'us-west-2',
        userPoolId: 'us-west-2_bxdNeqDGn',
        userPoolWebClientId: '467rlh361q573kme7313svd3sb',
        identityPoolId: 'us-west-2:5bad2932-df41-4b25-a2e2-15b8238a32c6',
    },
    test: {
        domain: 'flexinet-main-user-pool-test.auth.us-west-2.amazoncognito.com',
        redirectSignIn: 'https://console.test.flexi.network',
        redirectSignOut: 'https://portal.test.flexi.network',
        region: 'us-west-2',
        userPoolId: 'us-west-2_UuiFFepVO',
        userPoolWebClientId: '4l6lu4764mgkk0s6jfq5ebq2c8',
        identityPoolId: 'us-west-2:e32cbfbe-5359-4fa8-ab47-abfbd17f6f1b',
    },
    dev: {
        domain: 'flexinet-main-user-pool-dev.auth.us-west-2.amazoncognito.com',
        redirectSignIn: 'https://console.dev.flexi.network',
        redirectSignOut: 'https://portal.dev.flexi.network',
        region: 'us-west-2',
        userPoolId: 'us-west-2_INBgfMat1',
        userPoolWebClientId: '52m9svts4nvks7qi0ft8lt0oct',
        identityPoolId: 'us-west-2:4d212a6a-3a46-4d38-a43d-b434cd92f10e',
    },
    ide: {
        domain: 'flexinet-main-user-pool-dev.auth.us-west-2.amazoncognito.com',
        redirectSignIn: 'https://localhost:8012',
        redirectSignOut: 'https://localhost:8011',
        region: 'us-west-2',
        userPoolId: 'us-west-2_INBgfMat1',
        userPoolWebClientId: '52m9svts4nvks7qi0ft8lt0oct',
        identityPoolId: 'us-west-2:4d212a6a-3a46-4d38-a43d-b434cd92f10e',
    },
};

const oauth = {
    // Domain name
    domain: cognito[stage].domain,

    // Authorized scopes
    scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
    ],

    // Callback URL
    redirectSignIn: cognito[stage].redirectSignIn,

    // Sign out URL
    redirectSignOut: cognito[stage].redirectSignOut,

    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    responseType: 'token',

    // optional, for Cognito hosted ui specified options
    options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true,
    },
};

//OPTIONAL - Configuration for cookie storage
const cookieStorage = {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    domain: cognito[stage].domain,

    // OPTIONAL - Cookie path
    path: '/',

    // OPTIONAL - Cookie expiration in days
    expires: 365,

    // OPTIONAL - Cookie secure flag
    secure: true,
};

const useCookieStorage = false;

export const config = {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: cognito[stage].identityPoolId,

    // REQUIRED - Amazon Cognito Region
    region: cognito[stage].region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: cognito[stage].userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: cognito[stage].userPoolWebClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    cookieStorage: useCookieStorage ? cookieStorage : null,
    oauth: oauth,
};
