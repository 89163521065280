import PropTypes from 'prop-types';
import {Account,} from '../types';
import {PlatformPropertyCard} from '@flexinet/ui-components';


export const Profile = ({record}: { record: Account }) => {

    const properties =
        [
            {
                label: 'Name',
                value: record.name as string,
                span: 6,
            }, {
            label: 'Email',
            value: record.email as string,
            span: 6,
        }, {
            label: 'Status',
            value: record.status as string,
            span: 6,
        }, {
            label: 'Organization',
            value: record.organizationId as string,
            span: 6,
        }, {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateBankAccount',
            span: 3,
        },
            {
                label: 'Created',
                value: record.created as string,
                formatAs: 'Timestamp',
                span: 3,
            }, {
            label: 'Managed By',
            value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateBankAccount',
            span: 3,
        },
            {
                label: 'Last Updated',
                value: record.updated as string,
                formatAs: 'Timestamp',
                span: 3,
            }
        ];


    return (<PlatformPropertyCard properties={properties}/>);


};

Profile.propTypes = {
    record: PropTypes.shape({
                                name: PropTypes.string,
                                description: PropTypes.string,
                                status: PropTypes.string,
                                createdBy: PropTypes.shape({
                                                               givenName: PropTypes.string,
                                                               familyName: PropTypes.string,
                                                           }),
                                managedBy: PropTypes.shape({
                                                               givenName: PropTypes.string,
                                                               familyName: PropTypes.string,
                                                           }),
                                created: PropTypes.string,
                                updated: PropTypes.string,
                            }),
};


