import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/AccountsService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {Account} from '../types';
import config from "../config";

const entityKey = config.entityKey;

interface AccountsState {
    byId: { [key: string]: Account },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as AccountsState;


const normalizeRecord = (record: Account) => {

    return Object.assign({}, {
        id: record.id,
        name: record.name,
        email: record.email,
        status: record.status,
        organizationId: record.organizationId,
    }) as Account;
};

// generates pending, fulfilled and rejected
export const fetchAccounts = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (filter?: { [key: string]: string }) => {

        return fetchRecords(filter)
            .then((response) => {
                if (response) {
                    return response.map((record) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchAccount = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const saveAccount = createAsyncThunk(
    `${entityKey}/add`,
    (payload: Account) => {

        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateAccount = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: Account }) => {

        const normalized = Object.keys(record)
                                 .reduce((acc, key) => {
                                     if (record[key] !== undefined) {
                                         if (['email', 'created'].indexOf(key) === -1) {
                                             acc[key] = record[key];
                                         }
                                     }
                                     return acc;
                                 }, {} as Account);

        return update(id, normalized)
            .then((response) => {
                if (response) {
                    return normalizeRecord(record);
                }
            });
    },
);

export const deleteAccount = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchAccounts, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchAccount);

                                            // SAVE ONE
                                            saveOneCase(builder, saveAccount);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateAccount);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteAccount);
                                        },
                                    });

export default componentsSlice.reducer;
