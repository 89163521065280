import {
    deleteAccount,
    fetchAccount,
    saveAccount,
    updateAccount,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Account} from '../types';

export const useAccount = (accountId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, accountId ? accountId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof accountId === 'undefined') {
            throw new Error('accountId is required parameter');
        }
        dispatch(fetchAccount(accountId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Account) => {
        dispatch(saveAccount(values));
    };

    const updateRecord = (values: Account) => {
        if (typeof accountId === 'undefined') {
            throw new Error('accountId is required parameter');
        }

        dispatch(updateAccount({
                                   id: accountId,
                                   record: values,
                               }));
    };

    const deleteRecord = () => {
        if (typeof accountId === 'undefined') {
            throw new Error('accountId is required parameter');
        }
        dispatch(deleteAccount(accountId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
