import PropTypes from 'prop-types';
import {
    Field,
    FormikProps
} from 'formik';

import {Grid} from '@mantine/core';
import {
    FormikRadioGroup,
    FormikTextInput
} from "@flexinet/ui-components";
import {UserEmailsAutocomplete} from "../../users/inputs/UserEmailsAutocomplete.tsx";
import {OrganizationsAutocomplete} from "../../organizations/inputs/OrganizationsAutocomplete.tsx";
import {Account} from "../types.ts";


export const FormComponent = (props: FormikProps<Account>) => {

    const editing = typeof props.values.id !== 'undefined';

    return (
        <Grid p={'sm'} my={'sm'}>

            <Grid.Col span={12}>
                <Field
                    name='name'
                    label='Account Name'
                    description='Please give the account name'
                    component={FormikTextInput}
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='email'
                    label='Root User Email'
                    description='Please select user email'
                    component={UserEmailsAutocomplete}
                    disabled={editing}
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='status'
                    label='Status'
                    description='Please select status'
                    data={[{
                        label: 'ACTIVE',
                        value: 'ACTIVE'
                    }, {
                        label: 'SUSPENDED',
                        value: 'SUSPENDED'
                    }]}
                    component={FormikRadioGroup}
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='organizationId'
                    label='Organization'
                    description='Please select organization'
                    component={OrganizationsAutocomplete}
                />
            </Grid.Col>

        </Grid>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
