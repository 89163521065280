export const listUsers = `
   query listUsers($filter: UsersFilterInput) {
       listUsers(filter:$filter) {
           items {
              accountId
              accountAdmin
              address 
              birthDate
              email
              emailVerified
              enabled
              familyName
              gender
              givenName
              locale
              middleName
              name
              nickname
              phoneNumber
              phoneNumberVerified
              picture
              profile
              status
              username
              website
              zoneInfo
              sub
              created
              updated
           }
       }
   }
`;


export const getUser = `
   query getUser($username:ID!) {
       getUser(username:$username) {
          accountId
          accountAdmin
          address 
          birthDate
          email
          emailVerified
          enabled
          familyName
          gender
          givenName
          locale
          middleName
          name
          nickname
          phoneNumber
          phoneNumberVerified
          picture
          profile
          status
          username
          website
          zoneInfo
          sub
          created
          updated
       } 
}`;
