import {
    getAccount,
    listAccounts
} from './queries';
import {
    createAccount,
    deleteAccount,
    updateAccount,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {API} from 'aws-amplify';
import {
    Account,
    CreateAccount,
    DeleteAccount,
    GetAccount,
    ListAccounts,
    UpdateAccount
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";

// import {
//     notifyError,
//     notifySuccess
// } from "@flexinet/ui-components";


const normalizeFilter = (filter: { [key: string]: string }) => {
    return Object.keys(filter)
                 .reduce((acc: { [key: string]: object }, key: string) => {
                     if (filter[key] !== '') {
                         acc[key] = {eq: filter[key]};
                     }
                     return acc;
                 }, {});

};

export const fetchRecords = async (filter?: { [key: string]: string }) => {

    // filter looks like this - {organizationId: 'xxxxx', email: 'XXXXXXXXXXXXX'}

    let transformedFilter = {};
    if (typeof filter !== 'undefined') {
        transformedFilter = normalizeFilter(filter);
    }

    // after normalization filter looks like this - {organizationId: {eq: 'xxxxx'}, email: {eq: 'XXXXXXXXXXXXX'}}

    try {
        const response = await API.graphql<GraphQLQuery<ListAccounts>>({
                                                                           query: listAccounts,
                                                                           variables: {filter: transformedFilter},
                                                                       });
        return response.data?.listAccounts.items;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<GetAccount>>({
                                                                         query: getAccount,
                                                                         variables: {id: id},
                                                                     });
        notifySuccess('got account')
        return response.data?.getAccount;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await API.graphql<GraphQLQuery<CreateAccount>>({
                                                                            query: createAccount,
                                                                            variables: {input: payload},
                                                                        });
        notifySuccess();
        return response.data?.createAccount;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: Account) => {


    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;


    try {
        const response = await API.graphql<GraphQLQuery<UpdateAccount>>({
                                                                            query: (updateAccount),
                                                                            variables: {input: record},
                                                                        });

        notifySuccess('Account updated');
        return response.data?.updateAccount;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await API.graphql<GraphQLQuery<DeleteAccount>>({
                                                                            query: (deleteAccount),
                                                                            variables: {input: {id: id}},

                                                                        });
        notifySuccess('Account deleted');
        return response.data?.deleteAccount;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
