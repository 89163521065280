export const listAccounts = `
   query listAccounts($filter:AccountsFilterInput) {
       listAccounts(filter:$filter) {
           items {
              id
              name
              email
              status
              organizationId
           }
       }
   }
`;

export const listAccountsWithPagination = `
       listAccounts {
           items {
              id
              name
              email
              status
              organizationId
           }
       }
   }
`;

export const getAccount = `
   query getAccount($id:ID!) {
       getAccount(id:$id) {
         id
         name
         email
         status
         organizationId
       } 
}`;
