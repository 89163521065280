import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {User} from '../types';

export const columns = [
    {
        title: I18n.get('Name'),
        width: '1fr',
        render: (item: User) => <NavLink
            to={`/users/${item.id}`}>{item.name ? item.name : 'n/a'}</NavLink>,
    },
    {
        title: I18n.get('Email'),
        width: '1fr',
        render: (item: User) => item.email,
    },
    {
        title: I18n.get('Account'),
        width: '1fr',
        render: (item: User) => item.accountId,
    },
    {
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: User) => item.status,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: User) => formatTimestamp(item.created as string),
    },
    {
        title: I18n.get('Last Updated'),
        width: '1fr',
        render: (item: User) => formatTimestamp(item.updated as string),
    },
];
