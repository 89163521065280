import {AiOutlineArrowDown} from 'react-icons/ai';
import {
    DashboardWidgetBuilder,
    WidgetType
} from "@flexinet/ui-components";
import {NavLink} from "react-router-dom";

export const HealthWidget = () => {
    const widget: WidgetType = {
        header: {
            title: 'Platform Health',
        },
        body: {
            dataPoints: [
                {
                    value: 10,
                    label: 'Operational',
                    color: 'green'
                },
                {
                    value: 2,
                    label: 'Impared',
                    color: 'orange'
                },
                {
                    value: 1,
                    label: ' Down',
                    color: 'red'
                },
            ],
            metrics: [
                {
                    value: 99.96,
                    unit: '%',
                    label: 'Uptime',
                    color: 'black',
                    insight: {
                        icon: <AiOutlineArrowDown color="red"/>,
                        label: 'decrease of 0.01% from last month',
                        color: 'black',
                    },
                    visible: true
                },
            ],
        },
        footer: {
            actionItems: [
                {
                    label: 'Report Service Issue',
                    description: 'Use system to report issue',
                    visible: true,
                    component: {NavLink},
                    to: '/',
                },
                {
                    label: 'Subscribe To Updates',
                    description: 'Use our alerting system to receive updates',
                    visible: true,
                    component: {NavLink},
                    to: '/',
                },
            ],
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
