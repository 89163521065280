import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {v4 as uuidv4} from 'uuid';
import {
    AiOutlineAppstore,
    AiOutlineCloudServer,
    AiOutlineHome,
    AiOutlineLogin,
    AiOutlineWallet
} from 'react-icons/ai'
import {IconContext} from "react-icons";

export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Dashboard',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineHome/></IconContext.Provider>,
            rbac: 'ViewDashboard',
        }, {
            id: uuidv4(),
            link: '/organizations',
            label: 'Organizations',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineCloudServer/></IconContext.Provider>,
            rbac: 'ListOrganizations',
            children: [],
        }, {
            id: uuidv4(),
            link: '/accounts',
            label: 'Accounts',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineWallet/></IconContext.Provider>,
            rbac: 'ListAccounts',
            children: [],
        }, {
            id: uuidv4(),
            link: '/users',
            label: 'Users',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineLogin/></IconContext.Provider>,
            rbac: 'ListUsers',
            children: [],
        }, {
            id: uuidv4(),
            link: '/applications',
            label: 'Applications',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineAppstore/></IconContext.Provider>,
            rbac: 'ListApplications',
            children: [],
        }] as PlatformNavbarMenuItem[];

};

